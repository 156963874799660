.landingPageContainer {
  height: 60vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.overlay {
  height: 60vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.272);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.landingPageContainer h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  z-index: 2;
}

.ProfilePhoto {
  width: 105px;
  height: 105px;
  margin-bottom: 20px;
  border-radius: 100px;
  overflow: hidden;
  z-index: 2;
}
.ProfilePhoto img {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-position: center;
  object-fit: cover;
}

.landingPageContainer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  margin-bottom: 6px;
  z-index: 2;
}

.seeBlogsButton {
  width: 124px;
  height: 40px;
  background: linear-gradient(95.87deg, #ff7236 3.66%, #ff9236 97.11%);
  border-radius: 6px;
  outline: none;
  border: none;
  font-weight: 500;
  color: white;
  z-index: 2;
}

.profileButton {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: orange;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  color: white;
  z-index: 2;
}
