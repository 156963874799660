.about-container-wrapper {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .about-container {
    width: 500px;
    height: fit-content;
    padding: 34px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 100px;
    transition: 0.3s;
    z-index: 11;
    margin-top: 58vh;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
  }

  .about-container p{
    margin-top: 12px;
  }

  .about-container a{
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .about-container{
      width: 98%;
    }
  }
  