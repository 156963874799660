.DetailsWrapper {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.details {
  width: 500px;
  height: fit-content;
  padding: 20px 10px;
}
.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.head h5 {
  margin: 0;
}

.Photos{
    width: 100%;
    height: 150px;
    background-color: grey;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    cursor: pointer;
    overflow-x: visible !important;
}

.profileOne{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: red;
    transform: translate(-20px, 20px);
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.13);
}

.input {
  width: 100%;
  height: 44px;
  background-color: #05060f0a;
  border-radius: 0.5rem;
  padding: 0 1rem;
  border: 2px solid transparent;
  font-size: 1rem;
  transition: border-color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    background 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.inputAbout{
  width: 100%;
  height: 104px;
  background-color: #05060f0a;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  border: 2px solid transparent;
  font-size: 1rem;
  transition: border-color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    background 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.editable_Input{
    margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
  font-weight: normal;
  color: #05060f99;
  transition: color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.input:hover,
.input:focus,
.editable_Input:hover .input {
  outline: none;
  border-color: #28282a;
}

.editable_Input:hover .label,
.input:focus {
  color: #28282a;
}

.save{
  width: 100px;
  height: 40px;
  border: none;
  background: linear-gradient(95.87deg, #ff7236 3.66%, #ff9236 97.11%);
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-weight: 500;
}


@media (max-width: 500px) {
  .details{
    width: 100%;
    padding: 20px 16px;
  }
}