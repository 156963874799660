@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.App {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}

.rdw-option-wrapper:nth-child(5) {
  display: none !important;
}
.rdw-option-wrapper:nth-child(6) {
  display: none !important;
}
.rdw-option-wrapper:nth-child(7) {
  display: none !important;
}
.rdw-text-align-wrapper {
  display: none !important;
}
.rdw-option-disabled {
  display: none !important;
}
.rdw-block-wrapper {
  display: none !important;
}
.rdw-fontsize-wrapper {
  display: none !important;
}
.rdw-fontfamily-wrapper {
  display: none !important;
}
.rdw-link-wrapper {
  display: none !important;
}
.rdw-embedded-wrapper {
  display: none !important;
}
.rdw-emoji-wrapper {
  display: none !important;
}
.rdw-image-wrapper {
  display: none !important;
}
/* .rdw-option-wrapper{display: none !important;} */
.rdw-colorpicker-wrapper {
  display: none !important;
}
.rdw-remove-wrapper {
  display: none !important;
}
.rdw-history-wrapper {
  display: none !important;
}

.toolbarClassName {
  width: 100%;
  border: 2px solid rgba(124, 124, 124, 0.3) !important;
  border-radius: 8px 8px 0 0 !important;
  border-bottom: none !important;
  margin-bottom: 0 !important;
  justify-content: center !important;
  padding-top: 14px !important;
}
.editorClassName {
  width: 100%;
  border: 2px solid rgba(124, 124, 124, 0.3);
  border-radius: 0 0 8px 8px;
  margin-bottom: 10px;
  border-top: none;
}
.DraftEditor-root {
  width: 100% !important;
  padding: 0;
  border: none !important;
  overflow-wrap: normal !important;
  white-space: nowrap !important;
  padding: 16px !important;
}

/* signup container styling -------------------------------------------------------------------*/

.login-boss-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.main-container {
  width: 60vw;
  height: 65vh;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px 0px #00000026;
  border-radius: 10px;
}

.orange-rectangle {
  width: 35%;
  height: 108%;
  background: #ff8800;
  box-shadow: 2px 0px 15px 0px #00000057;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translate(20%, -4%);
          transform: translate(20%, -4%);
  transition: 0.3s;
}

.orange-rectangle h1 {
  position: fixed;
  color: white;
  font-weight: 900;
  font-size: 2.4rem;
}

.upper-triangle {
  position: fixed;
  top: 14.8%;
  border-right: 25px solid transparent;
  border-bottom: 16px solid #ff8800;
  left: 45.2%;
}

.lower-triangle {
  position: fixed;
  top: 81.8%;
  border-right: 16px solid transparent;
  border-bottom: 25px solid #ff8800;
  left: 45.2%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.text-container {
  /* background-color: rgba(222, 184, 135, 0.342);  */
  width: 20%;
  height: 65%;
  position: fixed;
  top: 18%;
  left: 53%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -webkit-animation: fadeInAnimation ease 0.3s;

          animation: fadeInAnimation ease 0.3s;
}

.wrapper {
  height: 50px;
  width: 100%;
  margin: 8px 0px;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
}

.text-container h2 {
  color: #ff8800;
  font-size: 20px;
}

.wrapper h3 {
  text-align: center;
  color: #9d9d9d;
  font-weight: 400;
  font-size: 18px;
}

.continue-with-google {
  height: 40px;
  padding: 0% 3%;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  font-size: 1rem;
  color: #9d9d9d;
  box-shadow: 0px 0px 10px 0px #00000033;
}

.continue-with-google p {
  margin: 0;
}

.continue-with-google:hover {
  box-shadow: 0px 0px 20px 0px #00000033;
  transition: 0.3s;
}

.continue-with-google img {
  width: 30px;
  height: 30px;
}

.text-feild {
  height: 50px;
  width: 100%;
  background-color: cadetblue;
}

.text-feild-input {
  height: 100%;
  width: 100%;
  border-bottom: 2px solid #d6d5d5;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 20px;
  color: #9d9d9d;
  font-size: 18px;
  padding-left: 3%;
  /* background-color: chocolate; */
}

.text-feild-input:focus {
  outline: none;
  border-bottom: 2px solid #ff8800;
}
.verify-button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: #ff8800;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 18px;
}

.verify-button:hover {
  background-color: #ffffff;
  transition: 0.3s;
  color: #ff8800;
  border: 1px solid #ff8800;
}

.last-line {
  font-size: 0.9rem;
  text-align: center;
}

.horizontallyCenter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.last-line strong {
  color: #ff8800;
}

.last-line strong:hover {
  -webkit-text-decoration: underline 2px solid #ff8800;
          text-decoration: underline 2px solid #ff8800;
}

/* styling for login text input feilds---------------------------------------------------------------- */

/* main container .text-container is used */

.eye-icon-css {
  color: grey;
  position: fixed;
  right: 28%;
  top: 38.5%;
  cursor: pointer;
}

.enterDetails-container {
  /* background-color: rgba(222, 184, 135, 0.342); */
  width: 30%;
  height: 40%;
  position: fixed;
  top: 20%;
  left: 47%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-animation: fadeInAnimation2 ease 0.3s;
          animation: fadeInAnimation2 ease 0.3s;
}

/* profile photo uploading container */

.upload-profile-pic {
  padding: 6px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.upload-profile-pic p {
  padding-left: 5%;
}

.profile-pic {
  border: 1px solid #ff8800;
  background-color: grey;
  border-radius: 100px;
  width: 60px;
  height: 60px;
}

.custom-file-input::-webkit-file-upload-button {
  width: 150px;
  height: 30px;
  border: none;
  border: 1px solid grey;
  background-color: transparent;
  border-radius: 10px;
  margin-left: 4%;

  color: grey;
  font-size: 16px;
  font-weight: 700;
}

input[type="file"] {
  color: rgba(0, 0, 0, 0);
}

.custom-file-input::-webkit-file-upload-button:hover {
  border: 1px solid #ff8800;
  color: #ff8800;
}

.greeting {
  color: #ff8800;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.skip-button-container {
  position: absolute;
  right: -20px;
  bottom: -130px;
  /* background-color: burlywood; */
}

.save-button-container {
  position: absolute;
  right: -20px;
  bottom: -130px;
}

.save-button-container,
.skip-button-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

#skipbutton {
  background-color: transparent;
  border: none;
  width: 167px;
  padding: 0px 12px;
  color: #ff8800;
  font-weight: 400;
}

#skipbutton:hover {
  -webkit-text-decoration: underline 2px solid #ff8800;
          text-decoration: underline 2px solid #ff8800;
}

.default-button {
  width: 150px;
  height: 40px;
  border: none;
  background: #ff8800;
  border-radius: 10px;

  color: white;
  font-size: 16px;
  font-weight: 700;
}

.default-button:hover {
  background-color: #ffffff;
  transition: 0.3s;
  color: #ff8800;
  border: 1px solid #ff8800;
}

/* interest container styling--------------------------------------------------------------------------------------------------------- */

.interest-container {
  padding: 20px 0px;
  /* background-color: aliceblue; */
  width: 100%;
  height: 34vh;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.feildOfInt {
  padding: 4px 15px;
  margin: 5px;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 25px;
  transition: 0.2s;

  color: white;
  font-weight: 600;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.position {
  position: absolute;
  right: -25px;
  bottom: -60px;
}

.greeting p {
  margin: 0;
}

/*navbar styling starts here */
/* ---------------------------  -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------=----
--------------------------------------------------------------------------------------------------------===--- */
.ignoreTwo {
  display: none;
}
.mobile {
  display: none;
}
.ignore {
  display: none;
}
.hamburgerMenu {
  display: none;
}
.expanded-menu {
  background-color: white;
  height: 90vh;
  z-index: 2;

  position: fixed;
  top: 60px;
  right: -10px;

  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #00000026;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.navbar-container {
  width: 100%;
  height: 60px;
  /* background-color: rgb(69, 35, 196); */
  padding: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
  /* background-color: blueviolet; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 100px;
  height: 27px;
}

.contents {
  width: 50%;
  height: 50px;
  /* background-color: blueviolet; */

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navBarIcon {
  height: 50px;
  width: 50px;
  /* background-color: burlywood; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff8800;
  cursor: pointer;
  text-decoration: none;
  border-radius: 50px;
}

.navBarIcon:hover {
  color: #00438b;
  transition: 0.3s;
  background-color: #f3f3f3;
}

.searchbar-Container {
  /* background-color: aqua; */
  width: 62%;
  display: flex;
}

#searchBar {
  width: 400px;
  height: 40px;
  background: rgba(113, 113, 113, 0.1);
  border-radius: 50px 0px 0px 50px;

  border: none;
  padding-left: 7%;
}

#searchBar:hover {
  background: rgba(113, 113, 113, 0.14);
}

#searchBar:focus {
  outline: none;
}

.searchIcon {
  color: #ff8800;
}

.searchButton {
  height: 40px;
  width: 60px;
  border-radius: 0px 50px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(228, 88, 88); */
  background: rgba(113, 113, 113, 0.1);
}

.profilePhoto {
  height: 36px;
  width: 36px;
  border-radius: 20px;
  background-color: rgba(128, 128, 128, 0.212);
  /* border: 2px solid #FF8800; */
}

/* homepage  styling starts here */

/* ----------------------------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------- */

/* ask a question design-------------------------------------------------------------------------- */
.ask-a-question {
  width: 100%;
  height: 15vh;
  background-color: white;
  box-shadow: 0px 0px 4px 0px #00000026;
  padding: 0px 36px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: 0.3s;
  cursor: pointer;
}

.ask-a-question:hover {
  box-shadow: 0px 0px 10px 1px #00000026;
}

.ask-greeting {
  color: #999090;
  padding: 0;
  margin: 0;
}

.hompageContainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  display: flex;
  padding: 0;
  margin-top: 60vh;
  padding-top: 20px;
  border-radius: 15px;
  position: relative;
  z-index: 11;
}

.question-container {
  width: calc(100% - 37.5%);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background-color: #d4d4d4; */
  /* margin: 5px; */
}

.related-questions {
  width: 37.5%;
  height: 200px;
  background-color: rgb(255, 255, 255);
  margin: 0px 5px;
  /* border: 1px solid red; */
  box-shadow: 0px 0px 4px 0px #00000026;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px;
}

.LoadMore{
  width: 100%;
  height: 60px;
  /* background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%); */
  color: white;
  background-color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}
.LoadMore:active{
  background-color: rgb(197, 195, 195);
}

/* question container design-------------------------------------------------------------------------------------------------------- */

.question-tile {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background-color: white; */
  /* box-shadow: 0px 0px 4px 0px #00000026; */
  padding: 40px;
  /* margin: 10px 0px; */
}

.question-tag {
  font-size: 16px;
}
.question-tag strong:hover {
  -webkit-text-decoration: underline 1.5px solid #00438b;
          text-decoration: underline 1.5px solid #00438b;
  cursor: pointer;
}

.question-title {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  /* margin-bottom: 50px; */
  font-size: 1.8rem;
  line-height: 36px;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
}
.question-title:hover {
  color: #000000;
}

.timeDate {
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  color: rgb(145, 145, 145);
}

.ViewCount {
  color: rgb(83, 83, 83);
  font-size: 14px;
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
}
.ViewCount i {
  margin-right: 5px;
}

.blogActions {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}

.openBlogButton {
  height: 44px;
  width: 84%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  border-radius: 10px;
  outline: none;
  text-decoration: none;
  color: white;
  transition: 0.04s;
}
.openBlogButton:hover {
  color: white;
}
.openBlogButton:active {
  font-size: 13px;
  box-shadow: 0px 0px 20px 0px inset #00000033;
}

.shareButton {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff8f36;
  cursor: pointer;
  border: 1px solid #ff8f36;
  border-radius: 10px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #999999;
  margin: 30px 0px;
}

/* controls bar styling here--------------------------------------------------------------------------- */

.control-base {
  width: 100%;
  height: 50px;
  /* background-color: aquamarine; */
  display: flex;
  justify-content: space-between;
}

.left-controls,
.right-controls {
  padding: 5px 0px;
  display: flex;
  /* background-color: aqua; */
}

.left-controls {
  width: 84%;
}
.right-controls {
  width: 15%;
  justify-content: flex-end;
}

.controls {
  height: 40px;
  margin: 0px 0px;
  background-color: blue;
  border-radius: 50px;
}

.controls:hover {
  background-color: #f5f5f5;
}

.like-dislike {
  width: 30%;
  display: flex;
  /* border: 1px solid rgba(0, 67, 139, 0.2); */
  background-color: transparent;
}

.like,
.dislike {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00438b;
  /* background-color: #aFFAF3; */
  cursor: pointer;
  border-radius: 10px;
}
.like {
  border-radius: 10px 0px 0px 10px;
}
.dislike {
  border-radius: 0px 10px 10px 0px;
}
.like:hover,
.dislike:hover {
  background-color: #00438b10;
}

.answers,
.answer-button,
.views,
.comments,
.share,
.threeDots-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #FF880033; */
  background-color: transparent;
  padding: 0px 6px;
  margin: 0px 5px;
  cursor: pointer;
}
.share:active,
.answer-button:active,
.answers:active,
.views:active {
  background-color: #f0f0f0;
  transition: 0.3s;
}

.like-dislike span {
  font-size: 0.7rem;
  font-weight: 400;
}

.views {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.views span {
  font-size: 0.75rem;
  font-weight: 400;
}

.comments {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.comments i {
  color: #ff8800;
}
.comments span {
  font-size: 0.8rem;
}

.share {
  width: 40px;
}
.share i {
  color: #ff8800;
}

.threeDots-menu {
  width: 40px;
  border: none;
}
.threeDots-menu i {
  color: #9c9b9a;
}

.answer-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #0000001a;
}
.answers {
  width: 25%;
  font-size: 1rem;
}
.answers p {
  margin: 0;
}

.answer-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 75%;
  height: 40px;
  /* background-color: #00438B; */
}

.answer-button input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  color: rgb(216, 216, 216);
  padding-left: 15px;
  background-color: white;
}

.answer-button:hover {
  cursor: pointer;
}

/* open blog button */

.openBlog:hover {
  background: #fff5e7;
  transition: 0.2s;
}

.image-container {
  width: 100%;
  margin: 20px 0px;
  border-radius: 10px;
  overflow: hidden;
  /* text-align: center; */
}

.image-container img {
  width: 50%;
  border-radius: 10px;
}

.image-container-question-tile {
  width: 100%;
  margin: 20px 0px;
  border-radius: 10px;
  overflow: hidden;
  /* text-align: center; */
}
.image-container-question-tile img {
  width: 100%;
  border-radius: 10px;
}

/* chatboxx styling=------------------------------------------------------------------------ */

.chatbox {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #00438b;
  color: white;
  margin-top: 20px;
}
.notification-box {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #686a6d;
  color: white;
  margin-top: 20px;
}
.profile-box {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #5a7297;
  color: white;
  margin-top: 20px;
}

/* question body desings ---------------------------------------------------------------------------------*/

.base {
  display: flex;
  padding: 0%;
  overflow-y: auto;
  overflow-x: hidden;
}
.question-body {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #ffffff;
  margin: 59vh 0px 80px 0px;
  padding: 50px 50px;
  border-radius: 12px;
  position: relative;
  z-index: 10;
}

.question-body #content {
  margin-top: 10px;
  margin-bottom: 0;
}

.question-category-tag {
  height: 40px;
  width: 100%;
  /* background-color: #00438B30; */
  display: flex;
  align-items: center;
}

.tag {
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 10px;
  margin: 0px 3px;
  background-color: #00438b;
  border-radius: 30px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.tag:hover {
  background-color: #00438bda;
}

.qbody_images {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  /* background-color: lightgrey; */
}

.qbody_images::-webkit-scrollbar{
  visibility: visible;
}

.qbody_images img {
  width: auto;
  height: 100%;
  margin-right: 10px;
  border-radius: 6px;
}

#blogContent{
margin-top: 26px;
}

/* answers body designs ----------------------------------------------------------*/

.answer-body {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background-color: #5a729720; */
  padding: 10px 10px;
}

.who-answered {
  width: 100%;
  height: 60px;
  /* background-color: rgb(195, 175, 175); */

  display: flex;
  align-items: center;
}

.profile-info {
  width: 180%;
  height: 60px;
  /* background-color: #686A6D; */
  display: flex;
  align-items: center;
}

.profile-photo {
  width: 50px;
  height: 50px;
  background-color: #8197b8;
  border-radius: 50px;
  /* margin: 0px 15px; */
  margin-right: 15px;
}

.who-details {
  width: 70%;
  height: 50px;
  /* background-color: #d8d8d8; */
}

.who-details p {
  margin: 0;
}

.answer-content {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background-color: #FFFAF3; */
  padding: 10px 0px;
}

/* ask question popup designs------------------------------------------------------------------ */

.ask-question-popup {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: fadeInAnimation3 ease 0.5s;
          animation: fadeInAnimation3 ease 0.5s;
}

.popup {
  width: 90vw;
  height: 90vh;
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
}

div.DraftEditor-root {
  border: 1px solid #000;
  background-color: rgb(255, 255, 255);
  height: 70vh;
  width: 90vw;
  overflow-y: auto;
  padding: 10px;
  font-family: none;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

.site-footer {
  height: 40vh;
  background-color: #00438b;
  width: 100%;
}

/* all animations keyframes ----------------------------------------------------------------------------------------------------------------------- */

/* enter details animation */
@-webkit-keyframes fadeInAnimation2 {
  0% {
    opacity: 0;
    left: 44%;
  }
  100% {
    opacity: 1;
    left: 47%;
  }
}
@keyframes fadeInAnimation2 {
  0% {
    opacity: 0;
    left: 44%;
  }
  100% {
    opacity: 1;
    left: 47%;
  }
}

/* enter details animation for mobile devices */
@-webkit-keyframes fadeInAnimation2Mobile {
  0% {
    opacity: 0;
    left: 7%;
  }
  100% {
    opacity: 1;
    left: 10%;
  }
}
@keyframes fadeInAnimation2Mobile {
  0% {
    opacity: 0;
    left: 7%;
  }
  100% {
    opacity: 1;
    left: 10%;
  }
}

/* login and signup container animation  */
@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
    left: 50%;
  }
  100% {
    opacity: 1;
    left: 53%;
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    left: 50%;
  }
  100% {
    opacity: 1;
    left: 53%;
  }
}

/* login and signup container animations for mobile devices */
@-webkit-keyframes fadeInAnimationMobile {
  0% {
    opacity: 0;
    left: 7%;
  }
  100% {
    opacity: 1;
    left: 10%;
  }
}
@keyframes fadeInAnimationMobile {
  0% {
    opacity: 0;
    left: 7%;
  }
  100% {
    opacity: 1;
    left: 10%;
  }
}

/* ask question popup fade in animations */
@-webkit-keyframes fadeInAnimation3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAnimation3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* media qurries for screen width less than 1000px */

@media screen and (max-width: 1000px) {
  /* for authorization layout */
  .orange-rectangle,
  .lower-triangle,
  .upper-triangle {
    display: none;
  }
  .main-container {
    width: 90vw;
    height: 90vh;
  }
  .login-boss-container {
    background-color: #ff8800;
  }
  .text-container {
    /* background-color: rgba(222, 184, 135); */
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0%;
    left: 10%;
    -webkit-animation: fadeInAnimationMobile ease 0.3s;
            animation: fadeInAnimationMobile ease 0.3s;
  }
  .eye-icon-css {
    right: 15%;
    top: 40%;
  }

  /* .enterDetails-container styling */
  .enterDetails-container {
    /* background-color: rgba(222, 184, 135); */
    width: 80%;
    height: 100%;
    top: 7%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-animation: fadeInAnimation2Mobile ease 0.3s;
            animation: fadeInAnimation2Mobile ease 0.3s;
  }

  .skip-button-container {
    position: relative;
    bottom: -30%;
    right: 0%;
    /* background-color: burlywood; */
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .save-button-container {
    position: relative;
    bottom: -46%;
    right: 0%;
    width: 100%;
  }

  .upload-profile-pic {
    background-color: transparent;
  }

  .default-button {
    width: 100%;
    height: 40px;
    border: none;
    background: #ff8800;
    border-radius: 10px;

    color: white;
    font-size: 16px;
    font-weight: 700;
  }

  .default-button:hover {
    background-color: #ffffff;
    transition: 0.3s;
    color: #ff8800;
    border: 1px solid #ff8800;
  }

  /* interest container---------------------------------------------------------------------------------------------------------------- */
  .interest-container {
    padding: 30px 0px;
    /* background-color: aliceblue; */
    width: 100%;
    height: 30vh;
  }
  .position {
    position: absolute;
    right: 0px;
    bottom: 90px;
  }

  /*buttons properties for mobile---------------------------------------------------------------------------------------------------------------- */
  .verify-button:hover {
    background-color: #ff8800;
    transition: 0.01s;
    color: white;
    border: none;
  }
  .verify-button:active {
    background-color: #e27800;
    transition: 0.1s;
  }
  .default-button:hover {
    background-color: #ff8800;
    transition: 0.1s;
    color: white;
    border: none;
  }
  .default-button:active {
    background-color: #e27800;
    transition: 0.1s;
  }

  /* homepage mobile responsive css */
  /* ---------------------------------------------------------
  ---------------------------------------------------------
  ---------------------------------------------------------
  ---------------------------------------------------------
  --------------------------------------------------------- */

  .hompageContainer {
    display: flex;
    flex-direction: column;
    margin-top: 58vh;
    border-radius: 15px;
  }

  .question-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* margin: 5px; */
  }

  .related-questions {
    width: 100%;
    height: 200px;
    margin: 5px;
    position: relative;
    bottom: 100px;
    display: none;
  }

  .question-tile {
    padding: 15px 20px;
    /* margin: 10px 0px; */
  }

  /* media querries for navbar------------------------------------- */
  /* media querries for navbar------------------------------------- */
  /* media querries for navbar------------------------------------- */

  .contents {
    display: none;
  }
  .logo img {
    width: 76px;
    height: 20px;
  }

  .hamburgerMenu i {
    color: #ff8800;
  }
  .hamburgerMenu {
    margin: 5px 10px 0px 10px;
    height: 40px;
    width: 40px;
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #5a7297; */
  }

  .ignoreTwo {
    display: flex;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* background-color: #00438B; */
    margin-right: 16px;
  }

  .expanded-menu {
    background-color: white;
    height: 90vh;

    position: fixed;
    top: 60px;
    right: -10px;

    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #00000026;
    padding-right: 10px;
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .photoandname {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 30%;
    /* background-color: #aF822050; */
  }
  .photo {
    width: 70px;
    height: 70px;
    background-color: #d8d8d8;
    margin-left: 15px;
    border-radius: 50px;
  }

  .menuButton {
    /* background-color: aqua; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 15%;
    margin: 1% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -3%;
    padding: 0px 15%;
  }

  .expanded-menu-navbar {
    width: 90%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }

  .icon-base {
    width: 33%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00438b;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .ignore {
    width: 390px;
    height: 50px;
    display: flex;
    justify-content: center;
  }

  .nameAndEmail {
    text-align: right;
    font-size: 0.9rem;
    margin-right: 15px;
  }
  .nameAndEmail p {
    margin: 0;
  }
  .nameAndEmail h5 {
    margin: 0;
    font-weight: 700;
    color: #ff8800;
  }

  .line {
    width: 100%;
    margin-top: 30px;
    height: 0.7px;
    background-color: rgb(218, 218, 218);
  }
}
.marker {
  width: 29%;
  height: 4px;
  border-radius: 10px;
  background-color: #00438b;
  position: absolute;
  top: 48px;
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

/* media querries for control buttons , for making buttontext smaller so that text can fit into the small boxes. */
@media screen and (max-width: 770px) {
  .answers {
    font-size: 10px;
  }
  .answer-button input {
    font-size: 12px;
  }
  .answer-button {
    margin: 0;
  }
  .views span {
    font-size: 10px;
  }
  .like-dislike span {
    font-size: 10px;
  }
  .controls:active {
    background-color: #00438b10;
  }
  .controls:hover {
    background-color: transparent;
  }
  .comments span {
    font-size: 10px;
  }
  .question-body {
    padding: 40px 20px;
  }
  .image-container {
    width: 100%;
    margin: 20px 0px;
    border-radius: 10px;
    overflow: hidden;
  }

  .image-container img {
    width: 100%;
  }
}

@media screen and (max-width: 370px) {
  .answers {
    flex-direction: column;
  }
  .question-body {
    padding: 20px;
  }
  .image-container {
    width: 100%;
    margin: 20px 0px;
    border-radius: 10px;
    overflow: hidden;
  }

  .image-container img {
    width: 100%;
  }
}

.AddBlog_parentContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.AddBlog_Wrapper {
  width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* box-shadow: 0px 0px 4px 0px #00000026; */
  border-radius: 10px;
  padding: 12px;
}
.Content {
  padding: 10px 16px;
  width: 100%;
  background: #f0f0f0;
  border: 2px solid rgba(124, 124, 124, 0.3);
  border-radius: 8px;
  color: #f5f5f5;
  margin-top: 0px;
  font-size: 16px;
}
.forHide {
  display: none;
}

.Title_input {
  padding: 0px 16px;
  width: 100%;
  height: 60px;
  border: 1px solid lightgrey;
  color: #000000;
  margin-top: 10px;
  transition: 0.5s;
  font-size: 16px;
}


.beforeUpload {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  border: 1px dashed lightgrey;
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  margin-top: 0px;
  font-size: 16px;
}
.beforeUpload img{
  width: 18px !important;
  height: 18px;
  color: #000000;
  margin-right: 10px;
  opacity: 0.8;
}

.image-upload {
  width: 100%;
  margin: 10px 0px;
}
.image-upload img {
  width: 100%;
}
.PostBlogButtonContainer{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.publish{
  width: 130px;
  height: 40px;
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.publish:active{
  background: linear-gradient(90deg, #ffa984 1.07%, #ff831d 98.71%);
}
.publish img{
  width: 16px;
  margin-left: 10px;
}

/* Loader css */
.ImageLoading {
  margin-top: 8px;
  border: 3px solid #ff7136;
  border-radius: 50%;
  border-top: 6px solid transparent;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media (max-width: 770px) {
  .AddBlog_Wrapper {
    width: 98%;
  }
}

.onPhoto {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  width: auto;
  min-width: 80%;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}
.gallery-container-wrapper {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.gallery-container {
  width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding-bottom: 200px; */
  transition: 0.3s;
}

.AddImageButton {
  width: 100%;
  height: 60px;
  border: 2px dashed lightgrey;
  background-color: transparent;
  border-radius: 4px;
  margin: 20px 0px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#imgPreview {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  /* background-color: lightgrey; */
}

#imgPreview img {
  width: auto;
  height: 100%;
  margin-right: 10px;
  border-radius: 6px;
}

#imgPreview::-webkit-scrollbar {
  display: none;
}

.ImgUploadPopupWrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.505);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}

.ImgUploadPopup {
  padding: 20px;
  width: 90%;
  max-width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 12px;
  background-color: white;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.Imginput {
  padding: 0px 16px;
  width: 100%;
  height: 60px;
  border: 1px solid lightgrey;
  color: #000000;
  margin-top: 10px;
  transition: 0.5s;
  font-size: 16px;
  margin: 20px 0px;
}

.ImagesContainerBox {
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* border: 1px solid red; */
}
.ImagesContainerBox p {
  width: 90%;
  margin: 0px 0px;
  padding: 0;
}

.ImageScrollContainer {
  width: 100%;
  height: 156px;
  /* padding: 10px; */
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
}

.ImageScrollContainer img {
  height: auto;
  width: auto;
  margin-right: 5px;
  border-radius: 6px;
}

.delete_photos{
  position: absolute;
  width: 30px;
  height:30px;
  display:flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  top: 0;
  right: 0;
  cursor: pointer;
}

@media (max-width: 770px) {
  .gallery-container {
    width: 100%;
  }
}

.Sharebutton {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  position: fixed;
  bottom: 40px;
  right: 14vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.13);
}

@media (min-width: 768px) {
  .Sharebutton {
    bottom: 40px;
    right: 14vw;
  }
}
  
@media (max-width: 480px) {
  .Sharebutton {
    bottom: 40px;
    right: 30px;
  }
}

@media (max-width: 260px) {
  .Sharebutton {
    bottom: 40px;
    right: 30px;
  }
}

/* _____________________________ */
.universal_Loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 50;
}

/* __________________________________ */
.ConfirmationPopup_Container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.541);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 12;
}

.cnf_popup_body {
  width: 320px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 20px 10px 10px 10px;
}
.cnf_actions {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}
.action {
  width: 49%;
  height: 50px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;
  color: grey;
}

.PrimaryAction {
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  color: white;
}

/* ______________________________________ */
.social-media {
  width: fit-cntent;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.792);
  border: 1px solid rgb(121, 121, 121);
  padding: 14px 10px;
  border-radius: 10px;
  z-index: 10;
}

.social-media a {
  margin: 0px 12px;
  text-decoration: none;
}

.social-media a svg {
  /* color: #ffffff; */
  width: 16px;
  fill: currentColor;
}

.social-media a img{
  width: 20px;
  height: auto;
}
.social-media i{
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}


/* ______________ */
/* navigation menu styling */

.navigationWrapper{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  /* background-color: white; */
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  overflow-y: hidden;
}
.navOption{
  width: 50px;
  height: 50px;
  border-radius: 6px;
  display: flex;
  background-color: white;
  margin: 0px 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000000;
}
.load-row {
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.load-row span {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 2px;
  background: #f76002;
  border-radius: 50px;
  -webkit-animation: up-down6 0.5s ease-in infinite alternate;
          animation: up-down6 0.5s ease-in infinite alternate;
}

.load-row span:nth-child(2) {
  background: #e85b04c4;
  -webkit-animation-delay: 0.16s;
          animation-delay: 0.16s;
}

.load-row span:nth-child(3) {
  background: #e85b0491;
  -webkit-animation-delay: 0.32s;
          animation-delay: 0.32s;
}

.load-row span:nth-child(4) {
  background: #e85b0456;
  -webkit-animation-delay: 0.48s;
          animation-delay: 0.48s;
}

@-webkit-keyframes up-down6 {
  0% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}

@keyframes up-down6 {
  0% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}
.admin_wrapper {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 60vh; 
}

.blogList {
  width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 0px #00000026;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 200px;
  position: relative;
  z-index: 11;
  transition: 0.3s;
}
.blog {
  width: 100%;
  height: 80px;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 10px;
  border: 2px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 10px;
  color: grey;
  transition: 0.3s;
}
.blogtitle {
  width: 86%;
  color: black;
  font-size: 16px;
  cursor: pointer;
}
.deleteButton {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  cursor: pointer;
}
.deleteButton:hover {
  opacity: 1;
}

.actions {
  display: flex;
  width: 500px;
  height: 40px;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
}
.action_Button {
  box-shadow: 0px 0px 4px 0px #00000026;
  width: 49%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  background: linear-gradient(95.87deg, #ff7236 3.66%, #ff9236 97.11%);
  font-weight: semibold;
  color: white;
}

.logout {
  box-shadow: none;
  /* border: 2px solid #ff9236; */
  color: #ff7236;
  background: rgb(255, 255, 255) !important;
}

.PublishButton {
  width: 100%;
  height: 50px;
  background-color: rgb(135, 163, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border: none;
  color: rgb(16, 29, 148);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (max-width: 770px) {
  .admin_details {
    width: 94%;
  }
  .actions{
    width: 94%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* background-color: aliceblue; */
    /* padding: 10px; */
  }
  .blogList{
    width: 100%;
  }
  .admin_wrapper{
    padding: 0;
    margin-top: 59vh;
  }
}

.landingPageContainer {
  height: 60vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.overlay {
  height: 60vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.272);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.landingPageContainer h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  z-index: 2;
}

.ProfilePhoto {
  width: 105px;
  height: 105px;
  margin-bottom: 20px;
  border-radius: 100px;
  overflow: hidden;
  z-index: 2;
}
.ProfilePhoto img {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-position: center;
  object-fit: cover;
}

.landingPageContainer p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  margin-bottom: 6px;
  z-index: 2;
}

.seeBlogsButton {
  width: 124px;
  height: 40px;
  background: linear-gradient(95.87deg, #ff7236 3.66%, #ff9236 97.11%);
  border-radius: 6px;
  outline: none;
  border: none;
  font-weight: 500;
  color: white;
  z-index: 2;
}

.profileButton {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: orange;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  color: white;
  z-index: 2;
}

.buttonContainer {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 21;
}

#nav-container {
  width: 300px;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  transition: 0.5;
  background-color: rgba(0, 0, 0, 0.628);
}

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* -webkit--appearance: none; */
  border: 0;
  border-radius: 0;
  height: 50px;
  width: 30px;
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.icon-bar {
  display: block;
  width: 80%;
  height: 3px;
  background: #aaa;
  transition: 0.3s;
}
.icon-bar + .icon-bar {
  margin-top: 5px;
}

#nav-container:focus-within .button {
  pointer-events: none;
}
#nav-container:focus-within .icon-bar:nth-of-type(1) {
  -webkit-transform: translate3d(0, 8px, 0) rotate(45deg);
          transform: translate3d(0, 8px, 0) rotate(45deg);
}
#nav-container:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}
#nav-container:focus-within .icon-bar:nth-of-type(3) {
  -webkit-transform: translate3d(0, -8px, 0) rotate(-45deg);
          transform: translate3d(0, -8px, 0) rotate(-45deg);
}

#nav-content {
  z-index: 20;
  margin-top: 70px;
  padding: 20px 40px;
  width: 90%;
  max-width: 300px;
  height: calc(100% - 70px);
  color: black !important;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  transition: -webkit-transform 0.3s;

  transition: transform 0.3s;

  transition: transform 0.3s, -webkit-transform 0.3s;
  will-change: transform;
  contain: paint;
}


#nav-content .li {
  text-decoration: none;
  padding: 10px 5px;
  display: block;
  text-transform: uppercase;
  transition: color 0.1s;
  color: black;
  cursor: pointer;
}

#nav-content .li:hover {
  color: #ff7236;
}

#nav-content .li:not(.small) + .small {
  margin-top: auto;
}

.login_Box_wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  background-color: #fff;
  display: block;
  padding: 1rem;
  max-width: 350px;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.form-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: #000;
}
.input-container {
  position: relative;
}
.input-container input,
.form button {
  outline: none;
  border: 1px solid #e5e7eb;
  margin: 8px 0;
  overflow-x: visible !important;
}
.input-container input {
  background-color: #fff;
  padding: 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 300px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.input-container input:focus{
  outline: 2px solid orange;
}
.submit {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  width: 100%;
  border-radius: 0.5rem;
  text-transform: uppercase;
}
.signup-link {
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
}
.signup-link a {
  text-decoration: underline;
}

.DetailsWrapper {
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.details {
  width: 500px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px 10px;
}
.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.head h5 {
  margin: 0;
}

.Photos{
    width: 100%;
    height: 150px;
    background-color: grey;
    border-radius: 12px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    cursor: pointer;
    overflow-x: visible !important;
}

.profileOne{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: red;
    -webkit-transform: translate(-20px, 20px);
            transform: translate(-20px, 20px);
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.13);
}

.input {
  width: 100%;
  height: 44px;
  background-color: #05060f0a;
  border-radius: 0.5rem;
  padding: 0 1rem;
  border: 2px solid transparent;
  font-size: 1rem;
  transition: border-color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    background 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.inputAbout{
  width: 100%;
  height: 104px;
  background-color: #05060f0a;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  border: 2px solid transparent;
  font-size: 1rem;
  transition: border-color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    background 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.editable_Input{
    margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
  font-weight: normal;
  color: #05060f99;
  transition: color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.input:hover,
.input:focus,
.editable_Input:hover .input {
  outline: none;
  border-color: #28282a;
}

.editable_Input:hover .label,
.input:focus {
  color: #28282a;
}

.save{
  width: 100px;
  height: 40px;
  border: none;
  background: linear-gradient(95.87deg, #ff7236 3.66%, #ff9236 97.11%);
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-weight: 500;
}


@media (max-width: 500px) {
  .details{
    width: 100%;
    padding: 20px 16px;
  }
}
.about-container-wrapper {
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .about-container {
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 34px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 100px;
    transition: 0.3s;
    z-index: 11;
    margin-top: 58vh;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
  }

  .about-container p{
    margin-top: 12px;
  }

  .about-container a{
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .about-container{
      width: 98%;
    }
  }
  
