.Sharebutton {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  position: fixed;
  bottom: 40px;
  right: 14vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.13);
}

@media (min-width: 768px) {
  .Sharebutton {
    bottom: 40px;
    right: 14vw;
  }
}
  
@media (max-width: 480px) {
  .Sharebutton {
    bottom: 40px;
    right: 30px;
  }
}

@media (max-width: 260px) {
  .Sharebutton {
    bottom: 40px;
    right: 30px;
  }
}

/* _____________________________ */
.universal_Loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 50;
}

/* __________________________________ */
.ConfirmationPopup_Container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.541);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 12;
}

.cnf_popup_body {
  width: 320px;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 20px 10px 10px 10px;
}
.cnf_actions {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}
.action {
  width: 49%;
  height: 50px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;
  color: grey;
}

.PrimaryAction {
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  color: white;
}

/* ______________________________________ */
.social-media {
  width: fit-cntent;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.792);
  border: 1px solid rgb(121, 121, 121);
  padding: 14px 10px;
  border-radius: 10px;
  z-index: 10;
}

.social-media a {
  margin: 0px 12px;
  text-decoration: none;
}

.social-media a svg {
  /* color: #ffffff; */
  width: 16px;
  fill: currentColor;
}

.social-media a img{
  width: 20px;
  height: auto;
}
.social-media i{
  transform: translateY(2px);
}


/* ______________ */
/* navigation menu styling */

.navigationWrapper{
  width: fit-content;
  height: 50px;
  /* background-color: white; */
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  overflow-y: hidden;
}
.navOption{
  width: 50px;
  height: 50px;
  border-radius: 6px;
  display: flex;
  background-color: white;
  margin: 0px 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000000;
}