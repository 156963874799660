.admin_wrapper {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin-top: 60vh; 
}

.blogList {
  width: 500px;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px 0px #00000026;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 200px;
  position: relative;
  z-index: 11;
  transition: 0.3s;
}
.blog {
  width: 100%;
  height: 80px;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 10px;
  border: 2px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 10px;
  color: grey;
  transition: 0.3s;
}
.blogtitle {
  width: 86%;
  color: black;
  font-size: 16px;
  cursor: pointer;
}
.deleteButton {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  cursor: pointer;
}
.deleteButton:hover {
  opacity: 1;
}

.actions {
  display: flex;
  width: 500px;
  height: 40px;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
}
.action_Button {
  box-shadow: 0px 0px 4px 0px #00000026;
  width: 49%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  background: linear-gradient(95.87deg, #ff7236 3.66%, #ff9236 97.11%);
  font-weight: semibold;
  color: white;
}

.logout {
  box-shadow: none;
  /* border: 2px solid #ff9236; */
  color: #ff7236;
  background: rgb(255, 255, 255) !important;
}

.PublishButton {
  width: 100%;
  height: 50px;
  background-color: rgb(135, 163, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border: none;
  color: rgb(16, 29, 148);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

@media (max-width: 770px) {
  .admin_details {
    width: 94%;
  }
  .actions{
    width: 94%;
    height: fit-content;
    /* background-color: aliceblue; */
    /* padding: 10px; */
  }
  .blogList{
    width: 100%;
  }
  .admin_wrapper{
    padding: 0;
    margin-top: 59vh;
  }
}
