.AddBlog_parentContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.AddBlog_Wrapper {
  width: 500px;
  height: fit-content;
  /* box-shadow: 0px 0px 4px 0px #00000026; */
  border-radius: 10px;
  padding: 12px;
}
.Content {
  padding: 10px 16px;
  width: 100%;
  background: #f0f0f0;
  border: 2px solid rgba(124, 124, 124, 0.3);
  border-radius: 8px;
  color: #f5f5f5;
  margin-top: 0px;
  font-size: 16px;
}
.forHide {
  display: none;
}

.Title_input {
  padding: 0px 16px;
  width: 100%;
  height: 60px;
  border: 1px solid lightgrey;
  color: #000000;
  margin-top: 10px;
  transition: 0.5s;
  font-size: 16px;
}


.beforeUpload {
  width: 100%;
  height: fit-content;
  padding: 20px;
  border: 1px dashed lightgrey;
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  margin-top: 0px;
  font-size: 16px;
}
.beforeUpload img{
  width: 18px !important;
  height: 18px;
  color: #000000;
  margin-right: 10px;
  opacity: 0.8;
}

.image-upload {
  width: 100%;
  margin: 10px 0px;
}
.image-upload img {
  width: 100%;
}
.PostBlogButtonContainer{
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.publish{
  width: 130px;
  height: 40px;
  background: linear-gradient(90deg, #ff7136 1.07%, #ff9236 98.71%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}
.publish:active{
  background: linear-gradient(90deg, #ffa984 1.07%, #ff831d 98.71%);
}
.publish img{
  width: 16px;
  margin-left: 10px;
}

/* Loader css */
.ImageLoading {
  margin-top: 8px;
  border: 3px solid #ff7136;
  border-radius: 50%;
  border-top: 6px solid transparent;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 770px) {
  .AddBlog_Wrapper {
    width: 98%;
  }
}
