.buttonContainer {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 21;
}

#nav-container {
  width: 300px;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  transition: 0.5;
  background-color: rgba(0, 0, 0, 0.628);
}

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* -webkit--appearance: none; */
  border: 0;
  border-radius: 0;
  height: 50px;
  width: 30px;
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.icon-bar {
  display: block;
  width: 80%;
  height: 3px;
  background: #aaa;
  transition: 0.3s;
}
.icon-bar + .icon-bar {
  margin-top: 5px;
}

#nav-container:focus-within .button {
  pointer-events: none;
}
#nav-container:focus-within .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
#nav-container:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}
#nav-container:focus-within .icon-bar:nth-of-type(3) {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}

#nav-content {
  z-index: 20;
  margin-top: 70px;
  padding: 20px 40px;
  width: 90%;
  max-width: 300px;
  height: calc(100% - 70px);
  color: black !important;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  transition: transform 0.3s;
  will-change: transform;
  contain: paint;
}


#nav-content .li {
  text-decoration: none;
  padding: 10px 5px;
  display: block;
  text-transform: uppercase;
  transition: color 0.1s;
  color: black;
  cursor: pointer;
}

#nav-content .li:hover {
  color: #ff7236;
}

#nav-content .li:not(.small) + .small {
  margin-top: auto;
}
