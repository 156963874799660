.onPhoto {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image {
  width: auto;
  min-width: 80%;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}
.gallery-container-wrapper {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.gallery-container {
  width: 500px;
  height: fit-content;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding-bottom: 200px; */
  transition: 0.3s;
}

.AddImageButton {
  width: 100%;
  height: 60px;
  border: 2px dashed lightgrey;
  background-color: transparent;
  border-radius: 4px;
  margin: 20px 0px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#imgPreview {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  /* background-color: lightgrey; */
}

#imgPreview img {
  width: auto;
  height: 100%;
  margin-right: 10px;
  border-radius: 6px;
}

#imgPreview::-webkit-scrollbar {
  display: none;
}

.ImgUploadPopupWrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.505);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}

.ImgUploadPopup {
  padding: 20px;
  width: 90%;
  max-width: 500px;
  height: fit-content;
  border-radius: 12px;
  background-color: white;
  transform: translateY(-30px);
}

.Imginput {
  padding: 0px 16px;
  width: 100%;
  height: 60px;
  border: 1px solid lightgrey;
  color: #000000;
  margin-top: 10px;
  transition: 0.5s;
  font-size: 16px;
  margin: 20px 0px;
}

.ImagesContainerBox {
  position: relative;
  width: 100%;
  height: fit-content;
  /* border: 1px solid red; */
}
.ImagesContainerBox p {
  width: 90%;
  margin: 0px 0px;
  padding: 0;
}

.ImageScrollContainer {
  width: 100%;
  height: 156px;
  /* padding: 10px; */
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
}

.ImageScrollContainer img {
  height: auto;
  width: auto;
  margin-right: 5px;
  border-radius: 6px;
}

.delete_photos{
  position: absolute;
  width: 30px;
  height:30px;
  display:flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  top: 0;
  right: 0;
  cursor: pointer;
}

@media (max-width: 770px) {
  .gallery-container {
    width: 100%;
  }
}
